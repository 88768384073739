<template>
  <v-app>
    <!-- <keep-alive> -->
    <component :is="getThemeMode.layout" />
    <!-- </keep-alive> -->

    <customizer />
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { config } from '../../data/config'
  import store from '@/store'

  export default {
    components: {
      Customizer: () => import('@/layouts/common-drawer/Customizer'),
    },
    data () {
      return {
        layoutName: '',
      }
    },
    computed: {
      ...mapGetters(['getThemeMode']),
    },
    // mounted() {
    //   if (this.$route.params.layout) {
    //     this.changeThemeLayout(this.$route.params.layout);
    //   }
    // },
    methods: {
      ...mapActions(['changeThemeLayout']),
    },
    beforeRouteEnter(to, from, next) {
      if (to.path == `/${to.params.locale}/app/dashboard` && store.getters.getRoleNames.length > 0) {
        if (store.getters.getRoleNames.includes(config.roles[0])) {
          next(`/${to.params.locale}/app/dashboard/student`)
        } else if (store.getters.getRoleNames.includes(config.roles[1])) {
          next(`/${to.params.locale}/app/dashboard/teacher`)
        } else if (store.getters.getRoleNames.includes(config.roles[2])) {
          next(`/${to.params.locale}/app/dashboard/parent`)
        } else if (store.getters.getRoleNames.includes(config.roles[3])) {
          next(`/${to.params.locale}/app/dashboard/company`)
        }
      } else {
        next()
      }
    },
  }
</script>
